<div class="c-page-not-found">
    <div class="c-page-not-found__content">
        <div class="c-page-not-found__title">
            404
        </div>
        <div class="c-page-not-found__content-error">
            <h3><i class="fa fa-warning"></i> Oops! Page not found.</h3>
            <p class="c-page-not-found__content-body">
                We could not find the page you were looking for.
                Meanwhile, you may <a routerLink="/">return to main page</a>.
            </p>
        </div>
    <div>
</div>
