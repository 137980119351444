
        <div class="wo-dialog-alert__body" (mousemove)="doCropMove($event)"
            [class.nw-cursor]="crop.nwMove === true"
            [class.ne-cursor]="crop.neMove === true"
            [class.se-cursor]="crop.seMove === true"
            [class.sw-cursor]="crop.swMove === true"
        >
            <div class="awatar-edit__title">Crop Image</div>
            <div class="awatar-edit__wrapper">
                <div class="awatar-edit" [style.height]="param.wrapHeight + 'px'" [style.width]="param.wrapWidht + 'px'" #containerEl>
                    <img
                        [width]="param.width"
                        [height]="param.height"
                        [style.left]="param.left + 'px'"
                        [style.top]="param.top + 'px'"
                    #imageEl>
                    <div class="cropper-wrap-box"
                        [style.width]="param.width === null ? 0 : param.width + 'px'"
                        [style.height]="param.height === null ? 0 : param.height + 'px'"
                        [style.left]="param.left + 'px'"
                        [style.top]="param.top + 'px'"
                    >
                        <div class="cropper-bg"
                            style="top: 0; left: 0; right: 0;"
                            [style.height]="crop.top + 'px'"
                        ></div>
                        <div class="cropper-bg"
                            style="left: 0"
                            [style.width]="crop.left + 'px'"
                            [style.height]="crop.height + 'px'"
                            [style.top]="crop.top + 'px'"
                        ></div>
                        <div class="cropper-bg"
                            style="right: 0"
                            [style.width]="(param.width - crop.left - crop.width) + 'px'"
                            [style.height]="crop.height + 'px'"
                            [style.top]="crop.top + 'px'"
                        ></div>
                        <div class="cropper-bg"
                            style="bottom: 0; left: 0; right: 0;"
                            [style.height]="(param.height - crop.top - crop.height) + 'px'"
                        ></div>

                        <div class="cropper"
                            (mousedown)="doCropMoveStart($event, 'drugMove')"
                            [style.width]="crop.width + 'px'"
                            [style.height]="crop.height + 'px'"
                            [style.top]="crop.top + 'px'"
                            [style.left]="crop.left + 'px'"
                        ></div>

                        <div class="nh-st" style="cursor: nw-resize;"
                            [style.top]="(crop.top - 10) + 'px'"
                            [style.left]="(crop.left - 10) + 'px'"
                            (mousedown)="doCropMoveStart($event, 'nwMove')"
                        >
                            <div class="nh-tt"></div>
                        </div>
                        <div class="nh-st" style="cursor: ne-resize;"
                            [style.top]="(crop.top - 10) + 'px'"
                            [style.left]="(crop.left + crop.width - 10) + 'px'"
                            (mousedown)="doCropMoveStart($event, 'neMove')"
                        >
                            <div class="nh-tt"></div>
                        </div>
                        <div class="nh-st"  style="cursor: se-resize;"
                            [style.top]="(crop.top + crop.height - 10) + 'px'"
                            [style.left]="(crop.left + crop.width - 10) + 'px'"
                            (mousedown)="doCropMoveStart($event, 'seMove')"
                        >
                            <div class="nh-tt"></div>
                        </div>
                        <div class="nh-st" style="cursor: sw-resize;"
                            [style.top]="(crop.top + crop.height - 10) + 'px'"
                            [style.left]="(crop.left - 10) + 'px'"
                            (mousedown)="doCropMoveStart($event, 'swMove')"
                        >
                            <div class="nh-tt"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wo-dialog-alert__buttons">
            <a class="btn btn-primary" href="javascript:void(0);" (click)="confirm()">Confirm</a>
            <a class="btn btn-default" href="javascript:void(0);" (click)="close()">Close</a>
        </div>
    