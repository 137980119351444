import { Component } from '@angular/core';

@Component({
    selector: 'wo-delete-button',
    templateUrl: 'wo-delete-button.component.html',
    styleUrls: ['wo-delete-button.component.css'],
})

export class WoDeleteButtonComponent {
    constructor() {}
}
