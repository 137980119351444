
        <div class="wo-dialog-prompt__body">
            {{message}}
        </div>
        <div class="wo-dialog-prompt__input">
            <input class="form-control" type="text" #inputEl (keydown)="onKeyDown($event)" [(ngModel)]="value"/>
        </div>
        <div class="wo-dialog-prompt__buttons">
            <a class="btn btn-default" href="javascript:void(0);" (click)="close(false)">Cancel</a>
            <a class="btn btn-primary" href="javascript:void(0);" (click)="close(true)">OK</a>
        </div>
    