<div class="c-user-profile">
    <section class="content-header">
        <h1>{{dataUser.id == 0 ? 'New' : 'Edit'}} User</h1>
    </section>

    <div class="box2">
        <wo-flash></wo-flash>

        <div class="box-body">

            <form action="javascript:;" (submit)="onSave()">
                <div class="form-row">
                    <div class="form-row__label">
                    Username *
                    </div>
                    <div class="form-row__field" [ngClass]="{hasError: dataErrors['username']}">
                    <input class="form-control" type="text" name="username" [(ngModel)]="dataUser.username">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-row__label">
                    First Name *
                    </div>
                    <div class="form-row__field" [ngClass]="{hasError: dataErrors['first_name']}">
                    <input class="form-control" type="text" name="first_name" [(ngModel)]="dataUser.first_name">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-row__label">
                    Last Name *
                    </div>
                    <div class="form-row__field" [ngClass]="{hasError: dataErrors['last_name']}">
                    <input class="form-control" type="text" name="last_name" [(ngModel)]="dataUser.last_name">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-row__label">
                    Email *
                    </div>
                    <div class="form-row__field" [ngClass]="{hasError: dataErrors['email']}">
                    <input class="form-control" type="text" name="email" [(ngModel)]="dataUser.email">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-row__label">
                    Status
                    </div>
                    <div class="form-row__field" [ngClass]="{hasError: dataErrors['status']}">
                    <select class="form-control" name="status" [(ngModel)]="dataUser.status">
                        <option value="active">Active</option>
                        <option value="blocked">Blocked</option>
                        <option value="deleted">Deleted</option>
                    </select>
                    </div>
                </div>

                <div class="form-row" [style.display]="dialog.options.hideRole === true ? 'none' : 'block'">
                    <div class="form-row__label">
                        Role
                    </div>
                    <div class="form-row__field" [ngClass]="{hasError: dataErrors['role']}">
                        <select class="form-control" name="role" [(ngModel)]="dataUser.role">
                            <option value="admin">Admin</option>
                            <option value="public">Participant</option>
                        </select>
                    </div>
                </div>

                <div class="password-group" *ngIf="dataUser.role === 'admin'">
                    <div class="form-row">
                        <div class="form-row__label">
                            Password {{dataUser.id == 0 ? '*' : ''}}
                        </div>
                        <div class="form-row__field" [ngClass]="{hasError: dataErrors['password']}">
                            <input class="form-control" type="password" name="password" [(ngModel)]="dataUser.password">
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-row__label">
                            Confirm Password
                        </div>
                        <div class="form-row__field" [ngClass]="{hasError: dataErrors['password_repeat']}">
                            <input class="form-control" type="password" name="password_repeat" [(ngModel)]="dataUser.password_repeat">
                        </div>
                    </div>
                </div>

                <div class="controls-group btn-group">
                    <button class="btn btn-success">{{dataUser.id == 0 ? 'Create' : 'Update'}}</button>
                    <button (click)="doCancel($event)" class="btn btn-default">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>
